/*
  Contains all custom themes for the app
*/

// theme colors
$theme-colors: (
  "primary": #FF7A64,
  "secondary": #25232F,
  "danger": #ff4136
);

// modal colors
$modal-content-bg: #FF7A64;
$modal-content-border-color: #FF7A64;
$modal-header-border-color: #1A1A22;
$modal-footer-border-color: #FF7A64;

// card colors
$card-border-color: black;
$card-bg: #FF7A64;
$card-border-width: 2px;
// list group
$list-group-bg: #1A1A22;
$list-group-border-color: #FF7A64;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';